import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import EmpForm from './form';
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey } from '../../auxiliares/funciones';
import { getProvinces, getOrigins } from '../../auxiliares/maestros';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [keyForm, setKeyForm] = React.useState(getUniqueKey());
  const [key, setKey] = React.useState(getUniqueKey());
  const [provincias, setProvincias] = React.useState([]);
  const [origenes, setOrigenes] = React.useState([]);

    const campos = ['provincia','texto','nombre','direccion','telefono','horario','origen','fechaAlta'];
    const cabecera = ['Provincia','Texto','Nombre','Dirección','Teléfono','Horario','Origen','F. Alta'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                setProvincias(await getProvinces(usuario.token));
                setOrigenes(await getOrigins(usuario.token));
                setCargado(true)
                props.showLoader(false);
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Busca = () =>{
        const body = busqueda;
        props.showLoader(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token, body))
        fetch(`${CONFIG.api}business/list`, {
            method: 'POST',
            body: bodyJson,
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then((result) => {
                if (result.error === 0 && !isEmpty(result.data)) {
                    setLista(result.data);
                }else{
                    setLista([]);
                }
                setKey(getUniqueKey());
                // eslint-disable-next-line react/prop-types
                props.showLoader(false);
            })
    }

    const FormBus = {provincia: "", texto: "", semantico: "", origen: 0};
    const [busqueda, setBusqueda] = React.useState(FormBus);

    const abreMaps = (obj) =>{
        const fileLink = document.createElement('a');
        fileLink.href = obj.enlace;
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
    }

    const validaGMaps = (obj) => obj.enlace !== '' && obj.enlace !== null;
    const acciones = [
      {
        color:'success',
        nombre:'Maps',
        icono:<i />,
        funcion: abreMaps,
        estilo: {},
        condition: validaGMaps
      }
    ]

    const resetBusqueda = () =>{
        setBusqueda(FormBus);
        setKeyForm(getUniqueKey());
    }

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const comA = busqueda;
        comA[nombre] = valor
        setBusqueda(comA);
    }

    const exportaResultados = () =>{
        props.showLoader(true);
        const body = busqueda;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}business/export`,{
            method: 'PUT',
            body: bodyJson,
            headers: {'Content-Type': "application/json"},
            responseType: "arraybuffer"
        })
            // .then((response) => {
            .then(res => res.blob())
            .then((blob) =>{
                if(blob.type !== 'application/json'){
                    const file = window.URL.createObjectURL(new Blob([blob]));
                    const fileLink = document.createElement('a');
                    fileLink.href = file;
                    fileLink.setAttribute('download', `Resultados_Empresas.xlsx`);
                    fileLink.setAttribute('target', '_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                }else{
                    Swal.fire({
                        title: 'No hay registros con ese filtro',
                        buttons: [{label: 'Ok'}]
                    })
                }
                // eslint-disable-next-line react/prop-types
                props.showLoader(false);
            })
    }

    const theme = useTheme();

  return (    
    <Page title="Empresas" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#"
                                onClick={()=>Busca()}>
                                  Buscar
                                </Button>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginLeft: '15px'}} to="#"
                                onClick={()=>exportaResultados()}>
                                  Exportar Búsqueda
                                </Button>
                                <Button variant="contained" color="warning" sx={{ color: 'white', marginLeft: '15px'}} to="#"
                                onClick={()=>resetBusqueda()}>
                                  Borra Filtros
                                </Button>
                              </>}
                  title="Empresas"
          />
          <Divider style={{ margin: '15px'}} />
            { cargado &&
          <CardContent>
            <EmpForm key={keyForm} showLoader={props.showLoader} provincias={provincias} origenes={origenes} busqueda={busqueda} actualizaCampo={actualizaCampo}/>
          </CardContent>
            }
            { !isEmpty(lista) &&
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
            }
        </Card>
    </Page>
  );
}
