import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../componentes/forms/inputs/RTextField';
import RSelect from '../../componentes/forms/inputs/RSelect';

// ----------------------------------------------------------------------

export default function BusqForm({ showLoader, actualizaCampo, busqueda, provincias }) {
  React.useEffect(() => {
    showLoader(false);
    // eslint-disable-next-line
  },[]);

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1}>
        <RTextField type="text" name="texto" label="Texto*" actualizaCampo={actualizaCampo} value={busqueda.texto} md={6} />
        <RSelect name="provincia" label="Provincia*" actualizaCampo={actualizaCampo} value={busqueda.provincia} md={6}
          values={provincias}
        />
      </Grid>
    </form>
  );
}
